import * as THREE from 'three'
import { forwardRef, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'

const w = 1
const h = 1
const r = 0.1
const depth = 1
const s = new THREE.Shape()
s.moveTo(-w / 2, -h / 2 + r)
s.lineTo(-w / 2, h / 2 - r)
s.absarc(-w / 2 + r, h / 2 - r, r, 1 * Math.PI, 0.5 * Math.PI, true)
s.lineTo(w / 2 - r, h / 2)
s.absarc(w / 2 - r, h / 2 - r, r, 0.5 * Math.PI, 0 * Math.PI, true)
s.lineTo(w / 2, -h / 2 + r)
s.absarc(w / 2 - r, -h / 2 + r, r, 2 * Math.PI, 1.5 * Math.PI, true)
s.lineTo(-w / 2 + r, -h / 2)
s.absarc(-w / 2 + r, -h / 2 + r, r, 1.5 * Math.PI, 1 * Math.PI, true)

export function lerp(object, prop, goal, speed = 0.1) {
  object[prop] = THREE.MathUtils.lerp(object[prop], goal, speed)
}

const color = new THREE.Color()
export function lerpC(value, goal, speed = 0.1) {
  value.lerp(color.set(goal), speed)
}

const vector = new THREE.Vector3()
export function lerpV3(value, goal, speed = 0.1) {
  value.lerp(vector.set(...goal), speed)
}

export function calculateRefractionAngle(incidentAngle, glassIor = 2.5, airIor = 1.000293) {
  const theta = Math.asin((airIor * Math.sin(incidentAngle)) / glassIor) || 0
  return theta
}

const boxGeometry = new THREE.SphereGeometry()
boxGeometry.computeVertexNormals()

export const Box = forwardRef((props, ref) => {
  const inner = useRef(null)
  useFrame(() => {
    lerpC(inner.current.material.emissive, 'white', 0.1)
  })
  return (
    <group scale={0.25} ref={ref} {...props}>
      <mesh visible={false} geometry={boxGeometry} />
      <mesh ref={inner} geometry={boxGeometry}>
        <meshStandardMaterial color="#fff" toneMapped={false} emissiveIntensity={2} />
      </mesh>
    </group>
  )
})
